import React, { useEffect, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import '../Slider.css';
import '../App.css';

const topics = [
  { name: 'Large Structures', images: ['./ptype.png', 'f162.jpg'] },
  { name: 'PROTOTYPING', images: ['mash.png', './team_mash.jpg'] },
  { name: 'HIGH TEMP TOOLING', images: ['./dfam7.png', './image25a.jpg'] },
  { name: 'METROLOGY', images: ['./focused.PNG'] },
  { name: '5-AXIS MILLING', images: ['./machining.jpg'] },
  { name: 'TRIM TOOLS', images: ['./image25b.jpg'] },
  { name: 'ANALYSIS', images: ['./sim2.png'] },
  { name: 'SIMULATION', images: ['./sim3.png'] },
];

const Carousel = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  // Load delay effect
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 2000); // 2-second delay

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);

  return (
    <div className="carousel-container">
      {isLoaded ? (
        <Splide
          options={{
            type: 'loop',
            perPage: 2,
            perMove: 1,
            pagination: false,
            arrows: true,
            gap: '1.5em',
            breakpoints: {
              1000: {
                perPage: 1,
                gap: '1.5em',
                autoScroll: false,
              },
              768: {
                perPage: 1,
                autoScroll: false,
                gap: '1em',
              },
              576: {
                perPage: 1,
                gap: '0.5em',
              },
            },
            autoScroll: {
              speed: 1.5,
              pauseOnHover: true,
              autoStart: true,
              rewind: false,
            },
          }}
          extensions={{ AutoScroll }}
        >
          {topics.map((topic, index) =>
            topic.images.map((image, imgIndex) => (
              <SplideSlide key={`${index}-${imgIndex}`}>
                <div className="slide-content">
                  <h2 className="topic-title">{topic.name}</h2>
                  <div className="image-container">
                    <img src={image} alt={`${topic.name} ${imgIndex}`} />
                  </div>
                </div>
              </SplideSlide>
            ))
          )}
        </Splide>
      ) : (
        <div className="loading-message">LOADING . . .</div>
      )}
    </div>
  );
};

export default Carousel;
